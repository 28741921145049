<template>
  <div>
    <b-form-group :label="label">
      <b-form-select
        v-model="selected"
        @change="updateValue"
        v-bind="$attrs"
        v-on="$listeners"
        :disabled="disabled"
      >
        <template #first v-if="disabled">
          <b-form-select-option :value="value" disabled>
            {{ upperTextValue }}
          </b-form-select-option>
        </template>

        <template #first v-else>
          <b-form-select-option :value="null" disabled>
            -- Please Select an Option --
          </b-form-select-option>
        </template>

        <b-form-select-option
          v-for="option in options"
          :key="option.text"
          :value="option.value"
        >
          {{ option.text }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean
    },
    defaultValue: {
      type: String
    }
  },
  data() {
    return {
      selected: null,
      value: null
    };
  },
  methods: {
    updateValue(e) {
      this.$emit("input", e);
    },
    setDefaultValue() {
      this.value = this.defaultValue;
      this.selected = this.defaultValue;
    }
  },
  computed: {
    upperTextValue() {
      return this.value.toUpperCase();
    }
  },
  beforeUpdate() {
    if (this.defaultValue) {
      this.setDefaultValue();
    } else {
      this.value = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
