<template>
  <div>
    <b-row>
      <b-col>
        <div data-app>
          <v-data-table
            :headers="headers"
            :items="keys"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filter"
          >
            <template v-slot:top>
              <b-row class="mt-5 mb-3">
                <b-col sm="6" md="6">
                  <BaseButton
                    :squared="true"
                    variant="outline-success"
                    name="Add Rule(s)"
                    v-b-modal.modal-firewall
                  />
                </b-col>
                <b-col sm="6" md="6">
                  <b-input v-model="search" placeholder="Search"></b-input>
                </b-col>
              </b-row>
            </template>

            <template v-slot:item.controls="props">
              <BaseButton
                :squared="true"
                variant="outline-danger"
                name="Remove"
              />
            </template>
          </v-data-table>
        </div>
      </b-col>
    </b-row>

    <BaseModal
      id="modal-firewall"
      modalTitle="Tambah Rule IPv4"
      okTitle="Simpan"
      cancelTitle="Batal"
      :centered="true"
      size="md"
      @ok="addRules"
    >
      <template v-slot:modal-content>
        <b-form>
          <BaseDropdown
            label="Application"
            :options="options.application"
            v-model="selected.application"
            @change="switchForm()"
          />

          <BaseDropdown
            v-if="custom && !icmp"
            label="Protocol"
            :options="options.protocol"
            v-model="form.protocol"
          />

          <BaseInput
            v-if="custom && !icmp"
            label="Port / Port Range"
            type="number"
            v-model="form.port"
          />

          <BaseInput
            v-if="custom && icmp"
            label="Type"
            type="number"
            v-model="form.icmpType"
          />

          <BaseInput
            v-if="custom && icmp"
            label="Code"
            type="number"
            v-model="form.icmpCode"
          />

          <BaseDropdown
            v-if="!custom && !icmp"
            label="Protocol"
            :options="options.protocol"
            :defaultValue="form.protocol"
            :disabled="true"
          />

          <BaseInput
            v-if="!custom && !icmp"
            label="Port / Port Range"
            type="text"
            :defaultValue="form.port"
            :disabled="true"
          />
        </b-form>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from "@/views/pages/product/lightsail/components/BaseButton.vue";
import BaseInput from "@/views/pages/product/lightsail/components/BaseInput.vue";
import BaseDropdown from "@/views/pages/product/lightsail/components/BaseDropdown.vue";
import BaseModal from "@/views/pages/product/lightsail/components/BaseModal.vue";

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseModal,
    BaseDropdown
  },
  data() {
    return {
      search: "",
      custom: true,
      icmp: false,
      form: {
        port: null,
        ip: null,
        protocol: null,
        icmpType: null,
        icmpCode: null
      },
      keys: [
        {
          port: 1,
          ip: "test-key-ssh-1",
          protocol: "29 Januari 2021",
          state: "open"
        },
        {
          port: 2,
          ip: "test-key-ssh-2",
          protocol: "29 Maret 2021",
          state: "open"
        },
        {
          port: 3,
          ip: "test-key-ssh-3",
          protocol: "19 Januari 2021",
          state: "open"
        },
        {
          port: 4,
          ip: "test-key-ssh-4",
          protocol: "13 April 2021",
          state: "open"
        },
        {
          port: 5,
          ip: "test-key-ssh-5",
          protocol: "26 Februari 2021",
          state: "open"
        },
        {
          port: 6,
          ip: "test-key-ssh-6",
          protocol: "09 Mei 2021",
          state: "open"
        }
      ],
      options: {
        application: [
          { text: "Custom", value: "custom" },
          { text: "ALL TCP", value: "all-tcp" },
          { text: "ALL UDP", value: "all-udp" },
          { text: "ALL Protocols", value: "all-protocol" },
          { text: "SSH", value: "ssh" },
          { text: "RDP", value: "rdp" },
          { text: "Custom ICMP", value: "custom-icmp" }
        ],
        protocol: [
          { text: "TCP", value: "tcp" },
          { text: "UDP", value: "udp" }
        ]
      },
      selected: {
        application: ""
      }
    };
  },
  computed: {
    headers() {
      return [
        { text: "Port Range", value: "port" },
        { text: "IP", value: "ip" },
        { text: "Protocol", value: "protocol" },
        { text: "State", value: "state" },
        { text: "", value: "controls", sortable: false, align: "right" }
      ];
    },
    upperProtocol() {
      if (this.form.protocol) {
        return this.form.protocol.toUpperCase();
      } else {
        return "";
      }
    }
  },
  methods: {
    flushForm() {
      this.form = {
        port: null,
        ip: null,
        protocol: null,
        icmpType: null,
        icmpCode: null
      };
    },
    filter(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    addRules() {
      console.log(this.form);
    },
    switchForm() {
      this.flushForm();
      this.custom = false;
      this.icmp = false;
      switch (this.selected.application) {
        case "custom-icmp":
          this.options.protocol = [
            { text: "TCP", value: "tcp" },
            { text: "UDP", value: "udp" }
          ];
          this.custom = true;
          this.icmp = true;
          break;
        case "all-tcp":
          this.form.protocol = "tcp";
          this.form.port = "0 - 65535";
          break;
        case "all-udp":
          this.form.protocol = "udp";
          this.form.port = "0 - 65535";
          break;
        case "all-protocol":
          this.form.protocol = "all";
          this.form.port = "0 - 65535";
          break;
        case "ssh":
          this.form.protocol = "tcp";
          this.form.port = "22";
          break;
        case "rdp":
          this.form.protocol = "tcp";
          this.form.port = "3389";
          break;
        default:
          this.options.protocol = [
            { text: "TCP", value: "tcp" },
            { text: "UDP", value: "udp" }
          ];
          this.custom = true;
      }

      console.log(this.selected.application);
    }
  }
};
</script>
